import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled, { css } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import players from "../data/players"
import { Helmet } from "react-helmet"

const Container = styled.div`
  margin-top: ${props => (props.index === 0 ? "60px" : 0)};
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;
  width: calc(100% + 40px); // twice the value of the parent's padding
  margin-left: -20px; // -1 * parent's padding

  @media all and (min-width: 992px) {
    ${props =>
      props.index !== 3 &&
      css`
        height: 100vh;
      `};
    flex-direction: ${props => (props.index === 3 ? "column" : "row")};
    margin-top: 0;
    padding: 0;
  }
`

const TextContainer = styled.div`
  @media all and (min-width: 992px) {
    flex: 1.5;
    height: 100%;
    padding: ${props => (props.index === 3 ? "80px" : "20px 80px")};
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const StyledImg = styled(Img)`
  width: calc(100% + 40px); // twice the value of the parent's padding
  margin-left: -20px; // -1 * parent's padding
  ${props =>
    props.index !== 3 &&
    css`
      height: 60vh;
    `};

  @media all and (min-width: 992px) {
    width: 100%;
    ${props =>
      props.index !== 3 &&
      css`
        flex: 1;
        height: 100%;
      `};
  }
`

const title = "The players"

const ThePlayers = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "players" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const [activeSubMenu, setActiveSubMenu] = useState("balreet")
  var mq =
    typeof window !== "undefined" && window.matchMedia("(min-width: 992px)")
  useEffect(() => {
    if (!mq.matches) setActiveSubMenu("balreet")

    const ids = ["balreet", "jodh", "jeninder", "the-team"]

    const spy = () => {
      console.log("spyPLAYER")
      const items = ids
        .map(id => {
          const element = document.getElementById(id)
          if (element) {
            return {
              inView: isInView(element),
              id,
            }
          } else {
            return false
          }
        })
        .filter(item => item)

      const firstTrueItem = items.reverse().find(item => !!item && item.inView)
      if (!firstTrueItem) {
        return false // dont update state
      } else {
        setActiveSubMenu(firstTrueItem.id)
      }
    }

    const isInView = element => {
      if (!element) {
        return false
      }
      const rect = element.getBoundingClientRect()
      return rect.y <= 0
    }

    let interval = setInterval(() => spy(), 1000)
    return () => {
      clearInterval(interval)
    }
  }, [activeSubMenu])

  return (
    <Layout activeMenu={title} activeSubMenu={activeSubMenu}>
      <SEO title="The Players" />
      <Helmet>
        <title>The Players</title>
        <meta
          name="description"
          content="Know more about Sambal Lab team players ..."
        />
      </Helmet>
      {players.map((player, index) => {
        const img = data.allFile.edges.find(
          ({ node }) => node.relativePath === player.src
        ).node
        return (
          <Container id={player.id} index={index}>
            <StyledImg
              index={index}
              fluid={img.childImageSharp.fluid}
              alt={player.title}
              imgStyle={{ objectPosition: "top center" }}
            />
            <TextContainer>
              <h4>{player.name}</h4>
              <p dangerouslySetInnerHTML={{ __html: player.biography }} />
            </TextContainer>
          </Container>
        )
      })}
      {/* <div id="the-team">
        <Image />
        <TextContainer>
          <h3>The team</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vitae ornare felis. Phasellus vehicula facilisis augue eget placerat. Donec quis felis sed odio mollis mollis sed ac mauris. Donec id tellus nec elit sagittis efficitur. Ut quis ultricies est, non tristique erat. Nam eget velit mi. Aenean ac malesuada lorem. Nam magna dolor, sodales sed odio ac, fermentum ultricies risus.</p>
        </TextContainer>
      </div> */}
    </Layout>
  )
}

export default ThePlayers
